import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close'; 
import Spinner from 'src/common/Spinner'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
Amplify.configure(awsconfig);
const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDedicatedmail = (props, { className, ...rest }) => {
  var expr = /^[a-zA-Z0-9._]*$/
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isAvailable, setIsAvailable] = useState(false);
  const [open, setOpen] = useState(false);
  //ConfiguredEmail
  const [values, setValues] = useState({
    ConfiguredEmail: ""
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const [isMailExits, setMailExits] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userMailDetails, setUserMailDetails] = useState({});
  const [formData, setForm] = useState({});
  const [dedicatedEmail,setDedicatedEmail]=useState("");
  useEffect(() => {
    setValues(props.userDetails);
    if(props.childParameters==1){
      handleClose();
    }
    values.ConfiguredEmail && values.ConfiguredEmail != '' ? setMailExits(true) : setMailExits(false);
    setDedicatedEmail(props.dedicatedEmail?props.dedicatedEmail:"");
    setMailExits(window.$Email)
  }, [props]);

  useEffect(() => {
    console.log("userMailDetails", userMailDetails);
    if (userMailDetails) {
      isMailavailable(userMailDetails)
    }
  }, [userMailDetails]);
  async function updateMail(e) {
    setIsLoading(true);
    setForm(e);
    handleClickOpen(e);
  }
  


  const isMailavailable = async (e) => {
    if (e == 'Available') {
      await setIsAvailable(true);
    } else {
      await setIsAvailable(false);
    }
  }

  const handleKeyPress = async (event) => {
    var processMail = event+"@" + dedicatedEmail;
    await getMailUser(processMail);
  };

  const getMailUser = async (e) => {
    setIsLoading(true);
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", ConfiguredEmail: e, tenantId:props.userSession.TenantId  },
        queryStringParameters: {
          module: "user",
          op: "getUserMail"
        }
      };
      var response = await API.post(apiName, path, myInit);
      await setUserMailDetails(response.value);
      await setIsLoading(false);
    }
    catch (error) {
      console.log("set login time error", error);
    }
  }
  const handleClickOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log('close');
    setOpen(false);
    setIsLoading(false);
  };
   
  return (<>
    <Spinner left={70} top={0} isLoading={isLoading} />
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={
        Yup.object().shape({
          ConfiguredEmail: Yup.string().max(255).matches(expr).required('Dedicated mail is required'),

        })
      }
      onSubmit={async (values) => {
        await updateMail(values);
        console.log("(JSON.stringify(values, null, 2)", (JSON.stringify(values, null, 2)));

      }}

    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            {isMailExits ?
              <CardHeader
                subheader="Dedicated email ID"
                title=""
              />
              : <CardHeader
                subheader="Create a dedicated email id for sending invoice"
                title=""
              />}
            <Divider />
            <CardContent>
              {isMailExits ?
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant="inherit">{values.ConfiguredEmail?values.ConfiguredEmail:""}
                    </Typography>
                  </Grid>
                </Grid> :
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>

                    <TextField
                      error={Boolean(touched.ConfiguredEmail && errors.ConfiguredEmail)}
                      fullWidth
                      helperText="Please specify the dedicated Email"
                      label=""
                      id="ConfiguredEmail"
                      name="ConfiguredEmail"
                      onChange={handleChange}
                      required
                      value={values.ConfiguredEmail?values.ConfiguredEmail:""}
                      defaultValue={values.length > 0 && values.ConfiguredEmail?values.ConfiguredEmail:""}
                      variant="outlined"
                      //onKeyPress={handleKeyPress}
                      onBlur={e => {
                        handleBlur(e)
                        let Email = e.currentTarget.value
                        handleKeyPress(Email);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography component="h5">
                      {'@'}{dedicatedEmail}
                    </Typography>
                  </Grid>
                  <Grid item >
                    {isAvailable ? <Button
                      variant="outlined"
                      className="successIcon"
                      style={{ color: "green" }}
                      startIcon={<DoneIcon />}
                    >
                      Available
                    </Button> : <Button
                        variant="outlined"
                        className="errorIcon"
                        style={{ color: "red" }}
                        startIcon={<CloseIcon />}
                      >
                        Not Available
                  </Button>}
                  </Grid>
                </Grid>}
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Notification"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you want to save?
          </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      No
          </Button>
                    <Button onClick={props.handleConfigureMail.bind(null,formData)} color="primary" autoFocus>
                      Yes
          </Button>

          
                  </DialogActions>
                </Dialog>
              </div>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                disabled={isMailExits ? isMailExits : isSubmitting}
                type="submit"
              >
                Save
          </Button>
            </Box>
          </Card>
        </form>

      )}
    </Formik>
  </>);
};

ProfileDedicatedmail.propTypes = {
  className: PropTypes.string,
  isMailExits: PropTypes.bool,
  handleConfigureMail:PropTypes.func
};

export default ProfileDedicatedmail;
