import React, { useState,useEffect } from 'react';
import clsx from 'clsx'; 
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
  Dialog,
  DialogContent, 
  Typography,
  IconButton,
  TableContainer,
  Paper
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight'; 
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify";
import Spinner from 'src/common/Spinner';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/MoreVert';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ReactJson from 'react-json-view'
import CloseIcon from '@material-ui/icons/Close';
import { useNavigate} from 'react-router-dom';
import Moment from 'react-moment'; 

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  parentDisable:{
    position: "fixed",
    top: 0,
    left: 0,
    background: "#666",
    opacity: 0.8,
    zIndex: 998,
    height: "100%",
    width: "100%",
  },  
  overlayBox: {
    position:"absolute",
    top:"50%",
    left:"50%",
    transform:"translate(-50%, -50%)",
    color:" white; background: #666666; opacity: .8;",
    zIndex: 1000
  }
}));

const Invoices = (props,{ className, ...rest }) => {
  const classes = useStyles();
  const [invoiceData,setInvoiceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  const [pageSize, setPageSize] = usePersistedState('pageSize', 25); 
  const [invoiceFilter] = useState(""); 
  const [menuAnchor, setMenuAnchor] = useState(null); 
  const [menuOpen, setMenuOpen] = useState(false);
  const [invoiceJson, setInvoiceJson] = useState({});
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({}); 
  const navigate = useNavigate();

  useEffect(() => {
    let isSubscribed = true; 
    const userFetch = async () => {
      if (props && isSubscribed) {
          var res= await GetPagingData({
            pageSize:5,
            page:0
           });
           console.log("got invoice",res);
           await setInvoiceData(res.data);
      }
      return () => isSubscribed = false; 
    }
    userFetch();
   
  }, []); 
  async function GetPagingData(query) {
    var pageSize = query.pageSize;
    var pageNo = (query.page + 1);
    let body = {};
    var   masterType = "",
      apiModule = "",
      op = "";
    setIsLoading(true);
    setPageSize(pageSize); 
    masterType = "Pdf Invoice";
    apiModule = "invoice";
    op = "listRecentInvoices";
    body = {
      type: masterType,      
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId,
      Filter: invoiceFilter,
      pageSize: pageSize,
      pageNo: pageNo,
      status:["Rejected","Processed"]
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  async function GetInvoiceJson() { 
    let body = {};
    var   masterType = "",
      apiModule = "",
      op = "";
    setIsLoading(true);
    setPageSize(pageSize); 
    masterType = "Pdf Invoice";
    apiModule = "invoice";
    op = "getInvoiceJson";
    body = {
      type: masterType,  
      pk:currentRow.pk,   
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId      
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  const handleClickOpen = (e) => {
   
    setInvoiceOpen(true); 
};
const handleClose1 = (e) => {
  setInvoiceOpen(false);
};
const DialogTitle =  (props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
  async function  viewJson() {
    var row = currentRow; 
    var invJson=await GetInvoiceJson();
    await setInvoiceJson(invJson);
    handleClickOpen();
 }
  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    await setCurrentRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true); 

  }
 
 async function handleMenuClose(){
  await setMenuOpen(false);
 }
 async function downloadInvoice(type) { 
     let body = {}; 
    let apiName = "OptiVIVeAPI";
    let path = "/module"; 
    body = {
      type: type,  
      pk:currentRow.pk,   
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId      
    };
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body:body,
      queryStringParameters: {
        module:"invoice",
        op: "downloadInvoice"
      }
    };
    await API.post(apiName, path, myInit).then(response => { 
      if(response.success){
      const link = document.createElement('a');
      link.href = response.link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
      
    }).catch(err => {
      console.log(err);
    }); 
  }
  function BootstrapTooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
      },
    }));
    const classes = useStylesBootstrap();
    return  <Tooltip arrow classes={classes} {...props} />;
  }
  return (
     
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    > 
    <Dialog open={invoiceOpen} onClose={e => handleClose1()} fullWidth={true}
    maxWidth={"md"} aria-labelledby="form-dialog-title">
    <DialogTitle   id="email-dialog-title" onClose={e => handleClose1()}>
      JSON View
    </DialogTitle>
    <DialogContent>
    <ReactJson src={invoiceJson} enableClipboard={true} />
    </DialogContent>
  </Dialog>
      <CardHeader title="Invoice" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700}>
        <div className={isLoading ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>      
        <Spinner left={70} top={0}  isLoading={isLoading} />
        </div>
          </div>  
          <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                 Invoice No
                </TableCell>
                <TableCell>
                  Vendor
                </TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Invoice Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData && invoiceData.map((invoice) => (
                <TableRow
                  hover
                  key={invoice.pk}
                >
                  <TableCell>
                    {invoice.InvoiceNo}
                  </TableCell>
                  <TableCell>
                    {invoice.SellerName}
                  </TableCell>
                  <TableCell>
                    <Moment format="DD/MM/YYYY">{invoice.InvoiceDate}</Moment>
                  </TableCell>
                  <TableCell>
                  <BootstrapTooltip
                      title={ invoice.Status==="Rejected" && invoice.Message?invoice.Message:"" }
                            >
                    <Chip
                      color="primary"
                      label={invoice.Status?invoice.Status:"Validated"}
                      size="small"
                    />
                  </BootstrapTooltip>
                  </TableCell>
                  <TableCell>
                  <Button        
        onClick={(event, row) => {
          console.log(invoice);
          openMenu(event, invoice);
        }}
          color="primary"
          endIcon={<MenuIcon />}
          size="large"
          variant="text"
        >
         </Button>
                  
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
        </Box>
      </PerfectScrollbar>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button        
        onClick={() => {
        navigate('/app/invoice/all')
        }}
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </Box>
      <Menu
            id="simple-menu"
            keepMounted
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={viewJson}>View Invoice Json</MenuItem>
            <MenuItem onClick={()=>downloadInvoice("json")}>
              Download Invoice Json 
            </MenuItem>
            <MenuItem onClick={()=> downloadInvoice("pdf")}>
              Download Invoice PDF 
            </MenuItem>
          </Menu>
    </Card>
  );
};

Invoices.propTypes = {
  className: PropTypes.string
};

export default Invoices;
