import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles,
  List
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  subItem,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }
  return (
    subItem
      ? (
        <List component="nav" className={classes.appMenu} disablePadding>
          <ListItem
            className={clsx(classes.item, classes.menuItem, className)}
            disableGutters
            {...rest}
            button
            onClick={handleClick}
            activeClassName={classes.active}
          >
            <Button
              className={classes.button}
            >
              {Icon && (
              <Icon
                className={classes.icon}
                size="20"
              />
              )}
              <span className={classes.title}>
                {title}
              </span>
            </Button>
            {open ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />

            <List component="div" disablePadding>
              {subItem.map((item, ind) => (
                <ListItem
                  key={ind}
                  activeClassName={classes.active}
                  button
                  className={classes.menuItem}
                >
                  <Button
                    className={classes.button}
                    component={RouterLink}
                    to={item.href}
                  >

                    <span className={classes.title}>
                      {item.title}
                    </span>
                  </Button>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      )
      : (
        <ListItem
          className={clsx(classes.item, className)}
          disableGutters
          {...rest}
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={RouterLink}
            to={href}
          >
            {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
            )}
            <span className={classes.title}>
              {title}
            </span>
          </Button>
        </ListItem>
      )

  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  subItem: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    })
  )
};

export default NavItem;
