import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from 'src/aws-exports';
import { useSnackbar } from 'notistack';
import TopBar from './TopBar';
import NavBar from './NavBar';

Amplify.configure(awsconfig);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [userDetails, setUserDetails] = useState({});
  const [isMobileNavOpen, setMobileNavOpen] = useState(false); 
  React.useEffect(() => {
    getUser()
  }, []);
  console.log('DashboardLayout', props); 
  async function handleLogout() {
    delete localStorage.email;
    await Auth.signOut();
    props.userHasAuthenticated(false);
    console.log('MenuAppBar props 2', props);
    navigate('/');
  } 
  async function handleNotification() {
    console.log('Hanle event');
    enqueueSnackbar('This is a success message!', {
      variant: 'success',
    }); 
  } 
  const getUser = async () => { 
    try {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", UserID: props.userSession.UserName, tenantId: props.userSession.TenantId },
        queryStringParameters: {
          module: "user",
          op: "getUserProfile"
        }
      };
      var response = await API.post(apiName, path, myInit);
      await setUserDetails(response.data);  
      console.log(userDetails);
    }
    catch (error) {
      console.log("set login time error", error);
    }
  }
  return (
    <div className={classes.root}>
      <TopBar handleNotification={() => { handleNotification(); }} handleLogout={() => { handleLogout(); }} onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar user={userDetails}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
