import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/opti-vive-logo-fullwhite.png"
      {...props}
    />
  );
};

export default Logo;
