import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon
} from 'react-feather';
import PersonIcon from '@material-ui/icons/Person';
import NavItem from './NavItem';
import { S3Image } from 'aws-amplify-react';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '',
    icon: ShoppingBagIcon,
    title: 'Invoice',
    subItems: [     
      { title: 'Extraction Failed', href: '/app/invoice/failed' },
      { title: 'Rejected', href: '/app/invoice/rejected' },
      { title: 'Processed', href: '/app/invoice/processed' }
    ]
  },
  {
    href: '',
    icon: ShoppingBagIcon,
    title: 'E-Invoice',
    subItems: [      
      { title: 'Extraction Failed', href: '/app/e-invoice/failed' },
      { title: 'Rejected', href: '/app/e-invoice/rejected' },
      { title: 'Validated', href: '/app/e-invoice/validated' }
    ]
  },
  {
    href: '/app/vendor',
    icon: UsersIcon,
    title: 'Vendors'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  }/* ,
  {
    href: '/login',
    icon: LockIcon,
    title: 'Login'
  },
  {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Register'
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'Error'
  } */
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({user, onMobileClose, openMobile }) => {
  // console.log(("navbar props",userSession));
  const classes = useStyles();
  const location = useLocation();  
  React.useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          to="/app/account"
        >
          {user.profilePicture ? 
          <S3Image theme={{ photoImg: { maxWidth:  '100px' , maxHeight:  '100px' , display: "flex", marginRight: "auto", padding: "14px" } }} level="public" imgKey={user.profilePicture} />
           : 
           <PersonIcon/> 
          }  
        </Avatar>
        <Typography 
          color="textPrimary"
          variant="h5"
        >
          {user.Name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
        >
          {user.CompanyName}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              subItem={item.subItems}
            />

          ))}
        </List>
      </Box>
      <Box flexGrow={1} />

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  userSession: PropTypes.object,
  user: PropTypes.object
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
