import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(), 
    avatarUrl: '/static/images/avatars/avatar_3.png', 
    name: 'Ekaterina Tankova',
    gstin: '304-428-3097',
    successrate:"85%",
    eamil:"120",
    api:"250",
    sftp:"130",
  },
  {
    id: uuid(), 
    name: 'Cao Yu',
    gstin: '304-428-3097',
    successrate:"85%",
    eamil:"120",
    api:"250",
    sftp:"130",
  },
  {
    id: uuid(),
    name: 'Alexa Richardson', 
     gstin: '304-428-3097',
    successrate:"85%",
    eamil:"120",
    api:"250",
    sftp:"130",
  },
  {
    id: uuid(), 
    name: 'Anje Keizer',  
    gstin: '304-428-3097',
    successrate:"85%",
    eamil:"120",
    api:"250",
    sftp:"130",
  },
  {
    id: uuid(), 
    name: 'Clarke Gillebert',  
    gstin: '304-428-3097',
    successrate:"85%",
    eamil:"120",
    api:"250",
    sftp:"130",
  },
  {
    id: uuid(), 
    name: 'Adam Denisov',  
    gstin: '304-428-3097',
    successrate:"85%",
    eamil:"120",
    api:"250",
    sftp:"130",
  },
  {
    id: uuid(), 
    name: 'Ava Gregoraci', 
    gstin: '304-428-3097',
    successrate:"85%",
    eamil:"120",
    api:"250",
    sftp:"130",
  },
  {
    id: uuid(), 
    name: 'Emilee Simchenko',
    gstin: '304-428-3097',
    successrate:"85%",
    eamil:"120",
    api:"250",
    sftp:"130",
  },
  {
    id: uuid(), 
    name: 'Kwak Seong-Min',  
    gstin: '304-428-3097',
    successrate:"85%",
    eamil:"120",
    api:"250",
    sftp:"130",
  },
  {
    id: uuid(), 
    name: 'Merrile Burgett',  
    gstin: '304-428-3097',
    successrate:"85%",
    eamil:"120",
    api:"250",
    sftp:"130",
  }
];
