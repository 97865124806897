import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/MoreVert';
import Moment from 'react-moment'; 
import {
  Box,
  Card, 
  makeStyles, 
  Typography, 
  Grid
} from '@material-ui/core'; 
import MaterialTable from "material-table";
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify"; 
import CustomizedTooltips from 'src/common/tooltIp'; 
const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  tooltipText: {
    wordWrap:"nowrap",
    float:"left",
    paddingRight: "9px",
    verticalAlign: "middle",
    display: "inline-flex",
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.78)",
    fontSize: "0.875rem"
    }
}));

const ListInvoices = (props,{ className, ...rest }) => {
  console.log("props",props);
  const classes = useStyles();   
  const [isLoading, setIsLoading] = useState(false); 
  const [pageSize, setPageSize] = usePersistedState('pageSize', 5); 
  const [currentRow, setCurrentRow] = useState({}); 
  const [menuAnchor, setMenuAnchor] = useState(null); 
  const [menuOpen, setMenuOpen] = useState(false); 
  const [invoicefilter] = useState(""); 
  const tableRef = React.createRef(); 
  async function openMenu(event, row) {
    let anchorElement = event.currentTarget;
    await setCurrentRow(row);
    if(anchorElement) await setMenuAnchor(anchorElement);
    await setMenuOpen(true); 
  } 
  async function GetPagingData(query) {
    var pageSize = query.pageSize;
    var pageNo = (query.page + 1);
    let body = {};
    var   masterType = "",
      apiModule = "",
      op = "";
    setIsLoading(true);
    setPageSize(pageSize); 
    masterType = "Signed Invoice";
    apiModule = "invoice";
    op = "listRecentInvoices";
    body = {
      type: masterType,      
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId,
      Filter: invoicefilter,
      pageSize: pageSize,
      pageNo: pageNo,
      status:'Validation Failed'
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "OptiVIVeAPI";
      let path = "/module";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        setBlankSpace(response.total,pageSize)
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  const setBlankSpace = (tol,pageSize) => {     
    if(pageSize != 5 && pageSize > tol ){
      if(tol>5)
      setPageSize(tol);
      else
      setPageSize(5);
    }
      
  }; 
 async function handleMenuClose(){
  await setMenuOpen(false);
 }
 async function downloadInvoice(type) { 
     let body = {}; 
    let apiName = "OptiVIVeAPI";
    let path = "/module"; 
    body = {
      type: type,  
      pk:currentRow.pk,   
      UserID: props.userSession.UserName, 
      tenantId:props.userSession.TenantId      
    };
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body:body,
      queryStringParameters: {
        module:"invoice",
        op: "downloadInvoice"
      }
    };
    await API.post(apiName, path, myInit).then(response => { 
      if(response.success){
      const link = document.createElement('a');
      link.href = response.link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
      
    }).catch(err => {
      console.log(err);
    }); 
  } 
  const createHeaderWithTooltip = (title, tooltip) => {
    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
        > <Grid item>
                <Typography className={classes.tooltipText}>{title}</Typography>
            </Grid>
            <Grid >
                <CustomizedTooltips content={tooltip} />
            </Grid>
        </Grid>
    );
  };
  return (
  <> 
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MaterialTable
            tableRef={tableRef}
            columns={[
              {
                title: 'Transaction No',
                field: 'TransactionNo',
                searchable: false,
                width:"5%",
                render: rowData => { return createHeaderWithTooltip(rowData.TransactionNo,rowData.Message)  }
              },
              {
                title: 'Source',
                field: 'source',
                searchable: false,
                width:"5%"
              },
              {
                title: 'Received On',
                field: 'ProcessedDate',
                searchable: false,
                render: rowData => <Moment format="DD/MM/YYYY">{rowData.ProcessedDate}</Moment>,
                width:"5%"
              } 
            ]}
           
            data={async query =>
              new Promise((resolve, reject) => {
                GetPagingData(query).then(result => {
                  resolve({
                    data: result.data,
                    page: result.page,
                    totalCount: result.total
                  });
                });
              })
            }
            options={{
              searchFieldStyle: {
                display: 'none'
              },
              actionsColumnIndex: -1,
              search: false,
              showTitle: false,
              toolbar: false,
              pageSize: pageSize,
              pageSizeOptions: [5, 25, 50, 100, 200]
            }}
            actions={[
              {
                icon: ()=> <MenuIcon/>,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, row) => {
                  openMenu(event, row);                   
                }
              }
           ]}
          />
         
        </Box>
      </PerfectScrollbar>
      <Menu
            id="simple-menu"
            keepMounted
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
          >      
            <MenuItem onClick={()=>downloadInvoice("attachment")}>
              Download JSON
            </MenuItem>            
          </Menu>
    </Card>
  </>);
};

ListInvoices.propTypes = {
  className: PropTypes.string
};

export default ListInvoices;