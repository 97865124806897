import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState, useEffect } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import Amplify, { Auth } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);
const App = (props) => {
  console.log('props present', props);
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userSession, setuserSession] = useState({});
  const routing = useRoutes(routes(
    isAuthenticated, userHasAuthenticated, userSession, setuserSession, props
  ));
  async function onLoad() {
    try {
      const user = await Auth.currentSession();
      console.log('ifSession present', user);
      if (user) {
        /** chack for user session present or not */
        userHasAuthenticated(true);
        setuserSession({
          FirstName: user.idToken.payload['custom:firstname'],
          LastName: user.idToken.payload['custom:lastname'],
          Email: user.idToken.payload.email,
          UserName: user.idToken.payload['cognito:username'],
          Privilege: user.idToken.payload['custom:privilege'],
          TenantId: user.idToken.payload['custom:tenantid'],
          AccountType: user.idToken.payload['custom:accounttype']
        });
      } else {
        console.log('Session NOT present');
        userHasAuthenticated(false);
        navigate('/');
      }
    } catch (e) {
      console.log('e.code', e);
      console.log('e.code', e ? typeof (e.code) : '');
      if ((e !== 'No current user' || e.code === 'UserNotFoundException') && props.location && props.location.pathname !== '/verify') {
        console.log('UserNotFoundException', e);
        userHasAuthenticated(false);
        navigate('/');
      }
    }
    setIsAuthenticating(false);
  }
  useEffect(() => {
    onLoad();
  }, []);
  return (
    !isAuthenticating && (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <SnackbarProvider maxSnack={3}>{routing}</SnackbarProvider>
    </ThemeProvider>
    )
  );
};

App.propTypes = {
  location: PropTypes.string
};

export default App;
