import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import Amplify, { API, Storage } from 'aws-amplify';

const BulkInvoiceUpload = ({ uploadOpen, ...rest }) => {
    const [open, setOpen] = React.useState(false);
    const [fileObjects, setFileObjects] = React.useState([]);
    const [invoices, setInvoices] = React.useState([]);
    React.useEffect(() => {
        setOpen(uploadOpen);
    }, [uploadOpen]);
    const dialogTitle = () => (
        <>
            <span>Upload invoice</span>
            <IconButton
                style={{ right: '12px', top: '8px', position: 'absolute' }}
                onClick={() => setOpen(false)}>
                <CloseIcon />
            </IconButton>
        </>
    );
    function getFileExtension(filename) {
        return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
    }
    const uploadToS3 = async (files) => {
        if (!files) return {}
        try {
            for await (const contents of files.map(file => file)) {
                console.log('uploadToS3', contents);
                let fileName = contents.file.name;
                let fileExt = getFileExtension(fileName);
                let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
                let result = await Storage.put(s3Key, contents.file, { contentType: contents.file.type });
                console.log('s3put result', result);
                return { FileKey: s3Key, FileName: fileName };
            }
        } catch (error) {
            console.log('upload error', error);
        }
    }

    return (
        <>
            <DropzoneDialogBase
                dialogTitle={dialogTitle()}
                acceptedFiles={['application/pdf']}
                fileObjects={fileObjects}
                cancelButtonText={"cancel"}
                submitButtonText={"submit"}
                maxFileSize={5000000}
                open={open}
                onAdd={newFileObjs => {
                    console.log('onAdd', newFileObjs, fileObjects);
                    setFileObjects([].concat(fileObjects, newFileObjs));
                    setFileObjects([]);
                }}
                onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                }}
                onClose={() => setOpen(false)}
                onSave={async () => {
                    console.log('onSave', fileObjects);
                    await uploadToS3(fileObjects);
                    setFileObjects([]);
                    setOpen(false);
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
            />
        </>
    )
}

export default BulkInvoiceUpload;