import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  makeStyles, Grid, Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  bgContainer: {
    backgroundImage: `url(${'static/inbound-invoice-automation.jpg'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    height: '100%'
  },
  halfContainer: {
    height: '100vh'
  },
  VCcontainer: {
    height: '100vh',
    position: 'relative'
  },
  optiViveVC: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  optiViveVCText: {
    fontFamily: 'Arial',
    color: '#fff',
    fontSize: '45px',
    fontWeight: 'bold'
  }
}));

const MainLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/*   <TopBar /> */}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Grid
              container
              direction="row"
              justify="center"

            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className={classes.bgContainer}>
                  <div className={classes.halfContainer}>
                    <div className={classes.VCcontainer}>
                      <div className={classes.optiViveVC}>
                        <Typography
                          className={classes.optiViveVCText}
                          variant="h2"
                        >
                          Opti-VIVe
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Outlet />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
