import React, { useState,useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import getInitials from 'src/utils/getInitials';
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify";
import Spinner from 'src/common/Spinner';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = (props) => {
  const classes = useStyles();
  const [customers] = useState(data);
  const [vendorData, setVendorData] = usePersistedState('vendorData',[]); 
  const [isLoading, setIsLoading] = useState(false); 
  useEffect(() => {
    const getVendorAggregations = async (e) => {
      setIsLoading(true);      
      try {
       let apiName = "OptiVIVeAPI";
       let path = "/module";
       let myInit = {
         headers: { "Content-Type": "application/json" },
         body: { masterType: "User", UserID: props.userSession.UserName, tenantId:props.userSession.TenantId  },
         queryStringParameters: {
           module: "invoice",
           op: "GetVendorAggregations"
         }
       };
        var response=await API.post(apiName, path, myInit);
        await setVendorData(response.data);
        await setIsLoading(false);
     }
     catch (error) {
       console.log("set login time error",error); 
     } 
    } 
    getVendorAggregations()
   },[]);
    
   async function handleSearch(e) {
    let target=e.target;
    if(e!=undefined){
    setIsLoading(true);      
      try {
       let apiName = "OptiVIVeAPI";
       let path = "/module";
       let myInit = {
         headers: { "Content-Type": "application/json" },
         body: { masterType: "User", UserID: props.userSession.UserName, tenantId:props.userSession.TenantId,
        filterParams:target.value
        },
         queryStringParameters: {
           module: "invoice",
           op: "GetVendorAggregations"
         }
       };
        var response=await API.post(apiName, path, myInit);
        await setVendorData(response.data);
        await setIsLoading(false);
     }
     catch (error) {
       console.log("set login time error",error); 
     } 
    }
  }
  
  return (
    <Page
      className={classes.root}
      title="Vendors"
    >
      <Container maxWidth={false}>
        <Toolbar handleSearch={handleSearch} />
        <Box mt={3}>
          <Results vendorData={vendorData} userSession={props.userSession}/>
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
