import React, { useState,useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link ,useNavigate,useLocation} from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import usePersistedState from 'src/utils/usePersistedState';
import { API } from "aws-amplify";
import Spinner from 'src/common/Spinner';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
})); 
 
  const Results = (props,{ className, customers, ...rest }) => {
    
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [vendorData, setVendorData] = usePersistedState('vendorData',[]); 
  const [isLoading, setIsLoading] = useState(false); 
  const [userSession, setUserSession] = useState({}); 
  
  const [switchState, setSwitchState] = React.useState({
    checkedA: true   
  });

  useEffect(() => {
    setUserSession(props.userSession);
  }, [props]); 
  const handleChange = (event) => {
    console.log("switch handle change",event.target);
    setSwitchState({ ...switchState, [event.target.name]: event.target.checked });
  };
  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    setVendorData(props.vendorData);    
   },[props]);
   const PurpleSwitch = withStyles({
    switchBase: {
      color: purple[300],
      '&$checked': {
        color: purple[500],
      },
      '&$checked + $track': {
        backgroundColor: purple[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>NAME</TableCell>
                <TableCell>GSTIN</TableCell>
              {/*   <TableCell>Process Immediately</TableCell> */}
                <TableCell>SUCCESS RATE</TableCell>
                <TableCell>EMAIL</TableCell>
                <TableCell>API</TableCell>
                <TableCell>SFTP</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendorData &&
                vendorData.map((customer, index) => (
                  <TableRow
                    hover
                    key={index}
                    selected={selectedCustomerIds.indexOf(index) !== -1}                 
                  >
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {customer.Vendor}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{customer.Gstin}</TableCell>
                   {/*  <TableCell>
                    <PurpleSwitch
                            checked={customer.ImmediateProcess?"Yes":"No"}
                            onChange={handleChange}
                            name="ImmediateProcess"
                          />
                    </TableCell> */}
                    <TableCell>{customer.Success}</TableCell>
                    <TableCell>{customer.Email}</TableCell>
                    <TableCell>{customer.Api}</TableCell>
                    <TableCell>{customer.Sftp}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={vendorData.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
