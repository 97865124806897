/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:dbec8309-4e67-41e1-8879-1555621b77db",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_RnnAtdlE9",
    "aws_user_pools_web_client_id": "4p72bs639a6kkp4sqnn0hcqnuj",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "OptiVIVeAPI",
            "endpoint": "https://en8ham4zwf.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        },
        {
            "name": "OptiVIVeMobileAPI",
            "endpoint": "https://438qseih29.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-south-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "optivive-dev",
            "region": "ap-south-1"
        }
    ],
    "aws_user_files_s3_bucket": "optivive-store174044-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1",
    "aws_content_delivery_bucket": "dev.opti-vive.com-dev",
    "aws_content_delivery_bucket_region": "ap-south-1",
    "aws_content_delivery_url": "https://d2o4z8h42pr7rj.cloudfront.net"
};


export default awsmobile;
