import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line, getLabelForValue } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider, 
  colors,
  makeStyles,
  useTheme,
  Button
} from '@material-ui/core'; 
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const useStyles = makeStyles(() => ({
  root: {
  },
  cardBottomPadding: {
    '&:last-child': {
      paddingBottom: '17px'
    }
  }
}));

const TrafficBySourceLine = ({op, userSession,traffic, className, ...rest }) => {
  const classes = useStyles(); 
  const data = {
    labels: traffic.label,
    datasets: [
      {
        label: 'EMAIL',  
        fill: false,
        backgroundColor: colors.indigo[500],
        borderColor: colors.indigo[500],
        data: traffic.emailData
      },
      {
        label: 'API',         
        fill: false,
        backgroundColor: colors.red[600],
        borderColor: colors.red[600],
        data: traffic.apiData
      },
      {
        label: 'SFTP', 
        fill: false,
        backgroundColor: colors.orange[600],
        borderColor: colors.orange[600],
        data: traffic.sftpData
      }  
    ]
  }
      
  const options ={
    responsive: true,  
    plugins: {
      legend: {
        position: 'top',
      }, 
    },
    scales: {     
      xAxes: {
        display: true,
        type:'linear', 
        scaleLabel: {
          display: true,
          labelString:'Day'          
        },
        ticks: {
          callback: function(val, index) {
            // Hide the label of every 2nd dataset
            return index % 2 === 0 ? getLabelForValue(val) : '';
          },
          beginAtZero: true,
        }        
      },
      yAxes: [{
        display: true,    
        min: 0, 
        ticks: { 
          stepSize: 2,
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
          labelString:'Count'          
        }
      }]
    }
  }
  
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Traffic" 
           action={(
            <Button
              endIcon={<ArrowDropDownIcon />}
              size="small"
              variant="text"
            >
              Last 7 days
            </Button>
          )}
      />
      <Divider />
      <CardContent className={classes.cardBottomPadding}>
        <Box
         height= {440}
         position= {'relative'}
        >
         <Line
           data={data}
           options={options}
        />      
        </Box> 
      </CardContent>
    </Card>
  );
};

TrafficBySourceLine.propTypes = {
  className: PropTypes.string
};

export default TrafficBySourceLine;
